// TrackingService.js
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { Crisp } from "crisp-sdk-web";

const useTrackingService = () => {
  const [cookies, setCookie] = useCookies(['userId', 'utm_source', 'utm_medium', 'utm_campaign']);
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  Crisp.configure('2d4d3bfd-8a2b-4a78-91f1-5a49c2419dc1');

  // Function to get UTM parameters from the URL
  function getUtmParameters() {
    let params = {};
    let parts = window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      params[key] = value;
    });
    return params;
  }

  useEffect(() => {
    let userId = cookies.userId;
    let utmSource = cookies.utm_source;
    let utmMedium = cookies.utm_medium;
    let utmCampaign = cookies.utm_campaign;

    if (!userId) {
      userId = uuidv4();
      setCookie('userId', userId, cookieOptions);
    }

    Crisp.setTokenId(userId);

    const utmParams = getUtmParameters();
    if (utmParams['utm_source'] || utmParams['utm_medium'] || utmParams['utm_campaign']) {
      // If UTM parameters are present in the URL, update the UTM cookies
      utmSource = utmParams['utm_source'] || utmSource;
      utmMedium = utmParams['utm_medium'] || utmMedium;
      utmCampaign = utmParams['utm_campaign'] || utmCampaign;
      setCookie('utm_source', utmSource, cookieOptions);
      setCookie('utm_medium', utmMedium, cookieOptions);
      setCookie('utm_campaign', utmCampaign, cookieOptions);
    }
  }, [cookies.userId, setCookie]);

  const cookieOptions = {
    path: "/",
    domain: ".outlier.care",
    sameSite: "none",
    secure: true,
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  };

  const recordEvent = (eventTag, props) => {
    const currentURL = window.location.href;
    const eventData = {
      eventTag,
      props: {
        ...props,
        currentURL: currentURL,
        utm_source: cookies.utm_source || '',
        utm_medium: cookies.utm_medium || '',
        utm_campaign: cookies.utm_campaign || '',
      },
    };
  
    axios.post(`${apiBaseURL}/api/mp`, eventData, { withCredentials: true })
      .then((response) => {
        // console.log("Event successfully tracked (from Client):", response);
      })
      .catch((error) => {
        console.error("Error tracking event:", error);
      });

    // Send event to Google Analytics
    window.gtag('event', eventTag);

    // Send event to Crisp
    Crisp.session.pushEvent(eventTag);
  };
  
  return {
    recordEvent,
    // ... other functions
  };
};

export default useTrackingService;