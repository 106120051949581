import { CategoryScale } from "chart.js";
import "../Styles/Components/SalaryChart.css";
import "../Styles/Reusable/buttons.css";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
Chart.register(CategoryScale);

const SalaryChart = ({ allSpecialtyDatasets }) => {
  const maxCompensation = allSpecialtyDatasets
    .flatMap((d) => d.salaryRanges.map((range) => range.max))
    .reduce((a, b) => Math.max(a, b), 0);

  const roundedMaxCompensation =
    Math.ceil(maxCompensation / 100000) * 100000 + 100000;

  const createSegments = (specialtyData) => {
    const allMinsAndMaxs = specialtyData.salaryRanges.flatMap((range) => [
      range.min,
      range.max,
    ]);
    const sortedMinsAndMaxs = Array.from(new Set(allMinsAndMaxs)).sort(
      (a, b) => a - b
    );

    const segments = [];

    segments.push({ min: 0, max: sortedMinsAndMaxs[0], count: 0 });

    for (let i = 0; i < sortedMinsAndMaxs.length - 1; i++) {
      const min = sortedMinsAndMaxs[i];
      const max = sortedMinsAndMaxs[i + 1];
      const count = specialtyData.salaryRanges
        .filter(
          (range) =>
            (range.min <= min && range.max > min) ||
            (range.min < max && range.max >= max)
        )
        .reduce((sum, range) => sum + parseInt(range.count, 10), 0);

      segments.push({ min, max, count });
    }

    segments.push({
      min: sortedMinsAndMaxs[sortedMinsAndMaxs.length - 1],
      max: roundedMaxCompensation,
      count: 0,
    });

    return segments;
  };

  const labels = allSpecialtyDatasets.map((d) => d.specialty);

  const labelsWithJobCount = allSpecialtyDatasets.map(
    (d) =>
      `${d.specialty} (${d.salaryRanges.reduce(
        (sum, range) => sum + parseInt(range.count, 10),
        0
      )})`
  );

  const allSpecialtySegments = allSpecialtyDatasets.map((specialtyData) =>
    createSegments(specialtyData)
  );
  const maxCounts = allSpecialtySegments.map((specialtySegments) =>
    Math.max(...specialtySegments.map((segment) => segment.count))
  );

  const frequencyColor = (count, maxCount) => {
    const opacity = count / maxCount;
    return `rgba(107, 85, 221, ${opacity})`; // 129, 108, 234
  };

  const chartData = {
    labels: labels,
    datasets: allSpecialtySegments.flatMap((segments, index) => {
      const specialtyData = allSpecialtyDatasets[index];
      const maxCount = maxCounts[index];
      return segments.map((segment) => {
        const barData = Array(labels.length).fill(0);
        barData[index] = segment.max - segment.min;

        const countData = Array(labels.length).fill(0);
        countData[index] = segment.count;

        return {
          label: `${
            specialtyData.specialty
          } | $${segment.min.toLocaleString()}-${segment.max.toLocaleString()} | ${
            segment.count
          } job${segment.count === 1 ? "" : "s"}`,
          data: barData,
          count: countData,
          backgroundColor: frequencyColor(segment.count, maxCount),
          borderWidth: 0,
          barPercentage: 0.9,
          categoryPercentage: 1,
          xAxisID: `${specialtyData.specialty}`, // xAxisID should match the key in the scales object
          stack: `${specialtyData.specialty}`,
        };
      });
    }),
  };

  const chartOptions = {
    type: "bar",
    data: chartData,
    scales: {
      // Note: this should be nested inside of `options` per the chart.js docs, 
      /// but that doesn't work. It breaks the x-axis labeling and legend.
      y: {
        max: roundedMaxCompensation,
        ticks: {
          callback: (value) => {
            if (value >= 1000000) {
              return `$${(value / 1000000).toFixed(1)}M`;
            } else {
              return `$${value / 1000}k`;
            }
          },
        },
        grid: {
          drawBorder: false,
          drawOnChartArea: false,
        },
      },
      x: {
        display: true,
        stacked: true,
        ticks: {
          callback: (value, index) => {
            return labelsWithJobCount[index];
          },
        },
      },
      ...labels.reduce((acc, label) => {
        acc[label] = {
          type: "category",
          id: label,
          display: false,
          ticks: {
            autoSkip: false,
          },
          stacked: true,
          position: "bottom",
        };
        return acc;
      }, {}),
    },
    responsive: true,
    maintainAspectRatio: false,
    options: {
      // indexAxis: "y",
    },
    plugins: {
      title: {
        display: false,
        text: "Annualized Compensation by Specialty",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return context.dataset.label;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

export default SalaryChart;