import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Styles/Components/JobList.css";
import "../Styles/Global/index.css";
import "../Styles/Reusable/buttons.css";
import "../Styles/Reusable/forms.css";
import "../Styles/Reusable/table.css";
import useTrackingService from '../services/TrackingService';
import { TRACKING_EVENT } from '../utils/TrackingEvents';

const JobList = () => {
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState("");
  const TrackingService = useTrackingService();

  useEffect(() => {
    fetchJobs();
    TrackingService.recordEvent(TRACKING_EVENT.JOB_PAGE_OPENED, {});
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/api/jobs`, {
        withCredentials: true,
      });
      const data = response.data;
      setJobs(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchBlur = () => {
    if (search.trim() !== "") {
      TrackingService.recordEvent(TRACKING_EVENT.JOB_LIST_SEARCHED, { searchQuery: search });
    }
  };

  const handleJobClick = (jobId, jobUrl) => {
    TrackingService.recordEvent(TRACKING_EVENT.JOB_OPENED, {
      jobId: jobId,
      jobUrl: jobUrl,
    });
    
    window.open(jobUrl, "_blank");
  };

  const filteredJobs = jobs.filter(
    (job) =>
      job["jobTitle"].toLowerCase().includes(search.toLowerCase()) ||
      job["jobLocation"].toLowerCase().includes(search.toLowerCase()) ||
      job["specialty"].toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="JobList">
      <div className="jobs-search-container">
        <input
          className="jobs-search-field"
          type="text"
          placeholder="Job Title, Location, or Specialty"
          value={search}
          onChange={handleSearch}
          onBlur={handleSearchBlur}
        />
      </div>

      {/* Table for larger screens (>800px) */}
      <div className="large-screen-table">
        <table>
          <thead>
            <tr>
              <th>Job Title</th>
              <th>Location</th>
              <th>Compensation</th>
              <th>Employment Type</th>
              <th>Setting</th>
              <th>Date Posted</th>
              <th>Specialty</th>
            </tr>
          </thead>
          <tbody>
            {filteredJobs.map((job, index) => (
              <tr key={index}>
                <td data-label="Job Title">
                  <a
                    href={job["jobUrl"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="job-link"
                    onClick={() => handleJobClick(job["_id"], job["jobUrl"])}
                  >
                    {job["jobTitle"]}
                  </a>
                </td>
                <td data-label="Location">{job["jobLocation"]}</td>
                <td data-label="Compensation">{job["jobCompensation"]}</td>
                <td data-label="Employment Type">{job["employmentType"]}</td>
                <td data-label="Setting">{job["jobSetting"]}</td>
                <td data-label="Date Posted">
                  {new Date(job["jobCreatedDate"]).toLocaleDateString("en-US")}
                </td>
                <td data-label="Specialty">{job["specialty"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Table for smaller screens (<800px) */}
      <div className="small-screen-table">
        <table>
          <thead>
            <tr>
              <th>Job Title</th>
              <th>Specialty</th>
              <th>Setting</th>
              <th>Compensation</th>
              <th>Location</th>
              <th>Date Posted</th>
              <th>Employment Type</th>
            </tr>
          </thead>
          <tbody>
            {filteredJobs.map((job, index) => (
              <tr key={index}>
                <td data-label="Job Title">
                  <a
                    href={job["jobUrl"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="job-link"
                    onClick={() => handleJobClick(job["_id"], job["jobUrl"])}
                  >
                    {job["jobTitle"]}
                  </a>
                </td>
                <td data-label="Specialty">{job["specialty"]}</td>
                <td data-label="Setting">{job["jobSetting"]}</td>
                <td data-label="Compensation">{job["jobCompensation"]}</td>
                <td data-label="Location">{job["jobLocation"]}</td>
                <td data-label="Date Posted">
                  {new Date(job["jobCreatedDate"]).toLocaleDateString("en-US")}
                </td>
                <td data-label="Employment Type">{job["employmentType"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobList;
