import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Styles/Components/Salaries.css";
import "../Styles/Reusable/buttons.css";
import "../Styles/Reusable/forms.css";
import { ReactComponent as ChevronIcon } from '../Assets/Chevron.svg';
import useTrackingService from '../services/TrackingService';
import { TRACKING_EVENT } from '../utils/TrackingEvents';

function Salaries() {
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const [salaryData, setSalaryData] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [search, setSearch] = useState("");
  const TrackingService = useTrackingService();

  useEffect(() => {
    document.title = 'Salaries | Outlier.Care';
  
    return () => {
      document.title = 'Outlier.Care';
    };
  }, []);

  useEffect(() => {
    axios.get(`${apiBaseURL}/api/compensation`, { withCredentials: true })
      .then((response) => {
        setSalaryData(response.data);
        TrackingService.recordEvent(TRACKING_EVENT.SALARIES_PAGE_OPENED, {});
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formatCurrency = (value) => {
    if (value === null || value === "") return "-";
    if (value >= 1000000) return "$" + (value / 1000000).toFixed(1) + "M";
    return "$" + value.toLocaleString();
  };

  const formatShortCurrency = (value) => {
    if (value === null || value === "") return "-";
    if (value >= 1000000) return "$" + (value / 1000000).toFixed(1) + "M";
    return "$" + Math.round(value / 1000) + "k";
  };

  const formatPercentage = (value) => {
    if (value === null || value === "") return "-";
    return value.toFixed(2) + "%";
  };

  const handleClick = (index) => {
    if (selectedRowIndex === index) {
      setSelectedRowIndex(null);
    } else {
      setSelectedRowIndex(index);
      TrackingService.recordEvent(TRACKING_EVENT.SALARY_DETAILS_EXPANDED, {});
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchBlur = () => {
    if (search.trim() !== "") {
      TrackingService.recordEvent(TRACKING_EVENT.SALARIES_SEARCHED, { searchQuery: search });
    }
  };

  const filteredComp = salaryData.filter(
    (salaryData) =>
      salaryData.specialty.toLowerCase().includes(search.toLowerCase()) ||
      salaryData.location.toLowerCase().includes(search.toLowerCase()) ||
      salaryData.employmentType.toLowerCase().includes(search.toLowerCase()) ||
      salaryData.employmentClassification
        .toLowerCase()
        .includes(search.toLowerCase())
  );

  return (
    <div className="Salaries">
      <div className="salaries-intro">
        <span className="salaries-intro-title">
          Explore Physician Compensation Data
        </span>
        <span className="salaries-intro-subtitle">
          Gain insights into physician comp using anonymous contributions from
          your peers.
        </span>
      </div>

      <div className="salaries-search-container">
        <input
          className="salaries-search-field"
          type="text"
          placeholder="Specialty, Employment Type/Class, Location"
          value={search}
          onChange={handleSearch}
          onBlur={handleSearchBlur}
        />
      </div>

      <table className="salaries-table">
        <thead>
          <tr>
            <th className="col-specialty">
              <div className="th1">Specialty</div>
              <div className="th2">Emp. Type | Class</div>
            </th>
            <th className="col-location">
              <div className="th1">Location</div>
              <div className="th2">Size</div>
            </th>
            <th className="col-years">
              <div className="th1">Years of Experience</div>
              <div className="th2">Years at Employer</div>
            </th>
            <th className="col-comp">
              <div className="th1">Normalized Comp</div>
              <div className="th2">Salary | Bonus</div>
            </th>
            <th className="col-icon"></th>
          </tr>
        </thead>
        <tbody>
          {filteredComp.map((data) => (
            <React.Fragment key={data.id}>
              <tr key={data.id} onClick={() => handleClick(data.id)}>
                <td className="col-specialty">
                  <div className="td1">{data.specialty}</div>
                  <div className="td2">
                    {data.employmentType} | {data.employmentClassification}
                  </div>
                </td>
                <td className="col-location">
                  <div className="td1">{data.location}</div>
                  <div className="td2">{data.communitySize}</div>
                </td>
                <td className="col-years">
                  <div className="td1">{data.experienceYears} yrs</div>
                  <div className="td2">{data.currentEmployerYears} yrs</div>
                </td>
                <td className="col-comp">
                  <div className="td1">
                    {formatCurrency(data.normalizedCompensation)}
                  </div>
                  <div className="td2">
                    {formatShortCurrency(data.salary)} |{" "}
                    {formatShortCurrency(data.incentiveBonus)}
                  </div>
                </td>
                <td className="col-chevron">
                  <ChevronIcon
                    className={`chevron-icon ${
                      selectedRowIndex === data.id ? "active" : ""
                    }`}
                  />
                </td>
              </tr>
              {selectedRowIndex === data.id && (
                <tr>
                  <td colSpan="4" className="row-details">
                    <div className="details-wrapper">
                      <div className="details-container">
                        <div className="detail">
                          <h3>{data.specialty}</h3>
                          <table className="salaries-detail-table">
                            <tbody>
                              <tr>
                                <td>Location:</td>
                                <td>{data.location}</td>
                              </tr>
                              <tr>
                                <td>Community Size:</td>
                                <td>{data.communitySize}</td>
                              </tr>
                              <tr>
                                <td>Employment Type:</td>
                                <td>{data.employmentType}</td>
                              </tr>
                              <tr>
                                <td>Employment Class:</td>
                                <td>{data.employmentClassification}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="detail">
                          <h3>
                            Normalized Compensation —{" "}
                            {formatCurrency(data.normalizedCompensation)}
                          </h3>
                          <table className="salaries-detail-table">
                            <tbody>
                              <tr>
                                <td>Salary:</td>
                                <td>{formatShortCurrency(data.salary)}</td>
                              </tr>
                              <tr>
                                <td>Incentive Bonus:</td>
                                <td>
                                  {data.incentiveBonusType === "$"
                                    ? formatCurrency(data.incentiveBonus)
                                    : formatPercentage(data.incentiveBonus)}
                                </td>
                              </tr>
                              <tr>
                                <td>Relocation Bonus:</td>
                                <td>
                                  {data.relocationBonusType === "$"
                                    ? formatCurrency(data.relocationBonus)
                                    : formatPercentage(data.relocationBonus)}
                                </td>
                              </tr>
                              <tr>
                                <td>Sign-on Bonus:</td>
                                <td>
                                  {data.signOnBonusType === "$"
                                    ? formatCurrency(data.signOnBonus)
                                    : formatPercentage(data.signOnBonus)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="detail">
                          <h3>Demographics</h3>
                          <table className="salaries-detail-table">
                            <tbody>
                              <tr>
                                <td>Years of Experience:</td>
                                <td>{data.experienceYears}</td>
                              </tr>
                              <tr>
                                <td>Years at Employer:</td>
                                <td>{data.currentEmployerYears}</td>
                              </tr>
                              <tr>
                                <td>Race:</td>
                                <td>{data.race || "-"}</td>
                              </tr>
                              <tr>
                                <td>Gender:</td>
                                <td>{data.gender || "-"}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="detail">
                          <h3>Benefits</h3>
                          <table className="salaries-detail-table">
                            <tbody>
                              <tr>
                                <td>Health Insurance:</td>
                                <td>{data.healthInsurance || "-"}</td>
                              </tr>
                              <tr>
                                <td>Paid Malpractice:</td>
                                <td>{data.paidMalpractice || "-"}</td>
                              </tr>
                              <tr>
                                <td>Matching 401K:</td>
                                <td>{data.matching401k || "-"}</td>
                              </tr>
                              <tr>
                                <td>CME Allowance:</td>
                                <td>{data.cmeAllowance || "-"}</td>
                              </tr>
                              <tr>
                                <td>Paid Vacation Days:</td>
                                <td>{data.paidVacationDays || "-"}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Salaries;
