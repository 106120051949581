// App.js
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from 'uuid';
import "../Styles/Components/App.css";
import "../Styles/Components/SalaryDashboard.css";
import "../Styles/Global/typography.css";
import "../Styles/Reusable/buttons.css";
import JobList from "./JobList";
import SalaryDashboard from "./SalaryDashboard";
import AddSalary from "./AddSalary";
import Salaries from "./Salaries";
import About from './About';
import useTrackingService from '../services/TrackingService';
import { TRACKING_EVENT } from '../utils/TrackingEvents';

function App() {
  const [cookies, setCookie] = useCookies(['userId']);
  const TrackingService = useTrackingService();

  useEffect(() => {
    document.title = 'Outlier.Care';
  
    return () => {
      document.title = 'Outlier.Care';
    };
  }, []);

  useEffect(() => {
    TrackingService.recordEvent(TRACKING_EVENT.VISITED_SITE, {});
  }, []);

  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="App">
        <header className="App-header">
          <div className="header-section logo">
            <NavLink to="/">
              <img
                src="/Outlier.Care-Logo.svg"
                alt="Compensation Clinic Logo"
              />
            </NavLink>
          </div>
          <nav className="header-section page-nav">
            <NavLink
              to="/salaries"
              className="button button-secondary"
              activeclassname="active"
            >
              Salaries
            </NavLink>
            <NavLink
              to="/"
              className="button button-secondary"
              activeclassname="active"
            >
              Jobs
            </NavLink>
            <NavLink
              to="/about"
              className="button button-secondary"
              activeclassname="active"
            >
              About
            </NavLink>
          </nav>
          <div className="header-section cta">
            <NavLink
              to="/add-salary"
              className="button button-primary"
              activeclassname="active"
            >
              Share Comp
            </NavLink>
          </div>
        </header>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <div className="intro">
                  <div className="hero-text">
                    <span>
                      <b>
                        Compare physician pay <br />
                        with{" "}
                      </b>
                    </span>
                    <span className="gradient-text">
                      <b>real data.</b>
                    </span>
                  </div>
                  <div className="intro-divider"></div>
                  <div className="sub-text">
                    Provider compensation is notoriously opaque. We provide real
                    compensation data from verified (anon) submissions and job
                    postings.
                  </div>
                </div>
                <SalaryDashboard />
                <JobList />
              </div>
            }
          />
          <Route path="/add-salary" element={<AddSalary />} />
          <Route path="/salaries" element={<Salaries />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;