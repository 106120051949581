// SalaryDashboard.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import SalaryChart from "./SalaryChart";
import SalaryFilters from "./SalaryFilters";
import "../Styles/Components/SalaryDashboard.css";

const SalaryDashboard = () => {
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const [jobs, setJobs] = useState([]);
  const [allSpecialtyDatasets, setAllSpecialtyDatasets] = useState([]);
  const [uniqueSpecialties, setUniqueSpecialties] = useState([]);
  const [filteredSpecialties, setFilteredSpecialties] = useState(null);

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${apiBaseURL}/api/jobs`, {
        withCredentials: true,
      });
      const data = response.data;
      setJobs(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (jobs.length > 0) {
      const specialtiesSet = new Set();
      jobs.forEach((job) => specialtiesSet.add(job.specialty));
      const specialties = Array.from(specialtiesSet).map((specialty) => ({
        name: specialty,
        jobCount: jobs.filter((job) => job.specialty === specialty).length,
      }));

      const sortedSpecialties = specialties.sort(
        (a, b) => b.jobCount - a.jobCount
      );
      const initialFilteredSpecialties = sortedSpecialties
        .slice(0, 3)
        .map((specialty) => specialty.name);
      setFilteredSpecialties(initialFilteredSpecialties);

      setUniqueSpecialties(specialties);
    }
  }, [jobs]);

  const handleFilterChange = (selectedSpecialties) => {
    setFilteredSpecialties(selectedSpecialties);
  };  

  useEffect(() => {
    if (jobs.length > 0 && filteredSpecialties) {
      const filteredData = preprocessData(jobs, filteredSpecialties);
      processData(filteredData);
    }
  }, [jobs, filteredSpecialties]); // WARNING: React Hook useEffect has a missing dependency: 'processData'. Either include it or remove the dependency array  react-hooks/exhaustive-deps

  const preprocessData = (jobs, filteredSpecialties) => {
    const groupedData = jobs.reduce((acc, row) => {
      if (
        filteredSpecialties.length === 0 ||
        filteredSpecialties.some((specialty) => row.specialty === specialty)
      ) {
        const specialty = row.specialty;
        const minCompensation = row.minAnnualizedCompensation;
        const maxCompensation = row.maxAnnualizedCompensation;

        const key = `${specialty},${minCompensation},${maxCompensation}`;

        if (!acc[key]) {
          acc[key] = {
            specialty: specialty,
            minAnnualizedCompensation: parseFloat(minCompensation),
            maxAnnualizedCompensation: parseFloat(maxCompensation),
            count: 0,
          };
        }
        acc[key].count++;
      }

      return acc;
    }, {});

    const pivotData = Object.values(groupedData);

    return pivotData;
  };

  const processData = (rawData) => {
    const data = Array.from({ length: rawData.length }, (_, i) => ({
      specialty: rawData[i].specialty,
      min: rawData[i].minAnnualizedCompensation,
      max: rawData[i].maxAnnualizedCompensation,
      count: rawData[i].count,
    })).reduce((acc, item) => {
      const index = acc.findIndex((x) => x.specialty === item.specialty);
      if (index === -1) {
        acc.push({
          specialty: item.specialty,
          salaryRanges: [
            {
              min: item.min,
              max: item.max,
              count: item.count,
            },
          ],
        });
      } else {
        acc[index].salaryRanges.push({
          min: item.min,
          max: item.max,
          count: item.count,
        });
      }
      return acc;
    }, []);
  
    // sort data according to the order of filteredSpecialties
    const sortedData = [...filteredSpecialties.map(
      filteredSpecialty => data.find(datum => datum.specialty === filteredSpecialty)
    )];
  
    setAllSpecialtyDatasets(sortedData);
  };  

  return (
    <div className="salary-dashboard-container">
      <h2>Job Posting Compensation Distribution</h2>
      <SalaryFilters
        specialties={uniqueSpecialties.sort((a, b) => b.jobCount - a.jobCount)}
        onFilterChange={handleFilterChange}
        filteredSpecialties={filteredSpecialties}
      />
      <div className="salary-chart-container">
        <SalaryChart allSpecialtyDatasets={allSpecialtyDatasets} />
      </div>
    </div>
  );
};

export default SalaryDashboard;
