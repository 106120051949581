import React, { useState, useEffect, useRef } from "react";
import { Dropdown, DropdownButton, Button, Form } from "react-bootstrap";
import "../Styles/Components/SalaryFilters.css";
import "../Styles/Reusable/buttons.css";
import useTrackingService from '../services/TrackingService';
import { TRACKING_EVENT } from '../utils/TrackingEvents';

const SalaryFilters = ({ specialties, onFilterChange }) => {
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const prevSpecialtiesRef = useRef();
  const [sortedSpecialties, setSortedSpecialties] = useState([]);
  const TrackingService = useTrackingService();

  useEffect(() => {
    if (
      specialties &&
      (!prevSpecialtiesRef.current ||
        specialties !== prevSpecialtiesRef.current) &&
      selectedSpecialties.length === 0
    ) {
      const initialSelectedSpecialties = specialties
        .slice(0, 3)
        .map((specialty) => specialty.name);
      setSelectedSpecialties(initialSelectedSpecialties);
    }
    prevSpecialtiesRef.current = specialties;
  }, [specialties, selectedSpecialties]);

  useEffect(() => {
    if (specialties) {
      const sorted = [...specialties].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setSortedSpecialties(sorted);
    }
  }, [specialties]);

  const handleSelect = (selectedSpecialty) => {
    if (!selectedSpecialties.includes(selectedSpecialty)) {
      const updatedSelectedSpecialties = [...selectedSpecialties, selectedSpecialty];
      setSelectedSpecialties(updatedSelectedSpecialties);
      TrackingService.recordEvent(TRACKING_EVENT.SALARY_FILTER_ADDED, {
        filterList: updatedSelectedSpecialties,
        filterAdded: selectedSpecialty,
      });
    }
  };  

  const handleDeselect = (deselectedSpecialty) => {
    setSelectedSpecialties((prev) => {
      const nextSelectedSpecialties = prev.filter(
        (specialty) => specialty !== deselectedSpecialty
      );
      if (nextSelectedSpecialties.length === 0) {
        return specialties.slice(0, 3).map((specialty) => specialty.name);
      }
      TrackingService.recordEvent(TRACKING_EVENT.SALARY_FILTER_REMOVED, {
        filterList: nextSelectedSpecialties,
        filterRemoved: deselectedSpecialty,
      });
      return nextSelectedSpecialties;
    });
  };

  useEffect(() => {
    if (selectedSpecialties.length > 0) {
      onFilterChange(selectedSpecialties);
    }
  }, [selectedSpecialties, onFilterChange]);

  return (
    <div className="salary-filters">
      <div className="salary-filters-dropdown">
        <DropdownButton
          id="dropdown-basic-button"
          title="Add Specialties"
          className="button-dropdown"
        >
          {sortedSpecialties &&
            sortedSpecialties.map((specialty) => (
              <Dropdown.Item key={specialty.name} as="button">
                <Form.Check
                  type="checkbox"
                  id={specialty.name}
                  label={`${specialty.name} (${
                    specialty.jobCount
                  } ${specialty.jobCount === 1 ? "job" : "jobs"})`}
                  checked={selectedSpecialties.includes(specialty.name)}
                  onChange={() =>
                    selectedSpecialties.includes(specialty.name)
                      ? handleDeselect(specialty.name)
                      : handleSelect(specialty.name)
                  }
                />
              </Dropdown.Item>
            ))}
        </DropdownButton>
      </div>
      <div className="salary-filters-button-group">
        {selectedSpecialties &&
          selectedSpecialties.map(
            (specialty) =>
              specialty && (
                <Button
                  key={specialty}
                  className="button-chip"
                  onClick={() => handleDeselect(specialty)}
                >
                  {specialty} <span>&times;</span>
                </Button>
              )
          )}
      </div>
    </div>
  );
};

export default SalaryFilters;
