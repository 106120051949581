import React, { useState, useEffect, useCallback } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  Dropdown,
  DropdownButton,
  InputGroup,
  Button,
} from "react-bootstrap";
import "../Styles/Components/AddSalary.css";
import "../Styles/Global/typography.css";
import "../Styles/Reusable/sections.css";
import "../Styles/Reusable/buttons.css";
import "../Styles/Reusable/forms.css";
import { ReactComponent as AddIcon } from "../Assets/Add.svg";
import useTrackingService from '../services/TrackingService';
import { TRACKING_EVENT } from '../utils/TrackingEvents';

function AddSalary() {
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const [specialties, setSpecialties] = useState([]);
  const [otherSpecialty, setOtherSpecialty] = useState(false); // WARNING: 'otherSpecialty' is assigned a value but never used. no-unused-vars
  const [races, setRaces] = useState([]);
  const [genders, setGenders] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [practiceTypes, setPracticeTypes] = useState([]);
  const [communitySizes, setCommunitySizes] = useState([]);
  const [showBonuses, setShowBonuses] = useState(false);
  const [showBenefits, setShowBenefits] = useState(false);
  const [showPersonalDemographics, setShowPersonalDemographics] =
    useState(false);
  const [isBonusActive, setIsBonusActive] = useState(false);
  const [isBenefitsActive, setIsBenefitsActive] = useState(false);
  const [isPersonalDemographicsActive, setIsPersonalDemographicsActive] =
    useState(false);
  const [salaryDefaults, setSalaryDefaults] = useState(null);
  const [salaryData, setSalaryData] = useState({
    specialty: "",
    otherSpecialty: "",
    salary: "",
    payPeriod: "",
    incentiveBonus: "",
    incentiveBonusType: "",
    relocationBonus: "",
    relocationBonusType: "",
    signOnBonus: "",
    signOnBonusType: "",
    healthInsurance: null,
    paidMalpractice: null,
    matching401k: null,
    cmeAllowance: null,
    paidVacationDays: "",
    otherBenefits: "",
    experienceYears: "",
    currentEmployerYears: "",
    race: null,
    gender: null,
    employmentType: "",
    employmentClassification: "",
    practiceType: "",
    location: "",
    communitySize: "",
    additionalComments: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const countErrors = useCallback(() => {
    let count = 0;
    for (let key in errors) {
      if (errors[key]) count++;
    }
    return count;
  }, [errors]);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let navigate = useNavigate();
  const TrackingService = useTrackingService();

  useEffect(() => {
    document.title = 'Share Comp | Outlier.Care';
  
    return () => {
      document.title = 'Outlier.Care';
    };
  }, []);

  useEffect(() => {
    const options = { withCredentials: true };
    TrackingService.recordEvent(TRACKING_EVENT.ASF_ADD_SALARY_PAGE_OPENED, {});

    axios
      .get(`${apiBaseURL}/api/compensation/specialties`, options)
      .then((res) => {
        setSpecialties(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${apiBaseURL}/api/compensation/races`, options)
      .then((res) => {
        setRaces(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${apiBaseURL}/api/compensation/genders`, options)
      .then((res) => {
        setGenders(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${apiBaseURL}/api/compensation/employmentTypes`, options)
      .then((res) => {
        setEmploymentTypes(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${apiBaseURL}/api/compensation/practiceTypes`, options)
      .then((res) => {
        setPracticeTypes(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${apiBaseURL}/api/compensation/communitySizes`, options)
      .then((res) => {
        setCommunitySizes(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${apiBaseURL}/api/compensation/defaults`, options)
      .then((res) => {
        setSalaryDefaults(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (salaryDefaults) {
      setSalaryData((prevData) => ({
        ...prevData,
        specialty: salaryDefaults.specialty || "",
        otherSpecialty: salaryDefaults.otherSpecialty || "",
        salary: salaryDefaults.salary || "",
        payPeriod: salaryDefaults.payPeriod || "",
        incentiveBonus: salaryDefaults.incentiveBonus || "",
        incentiveBonusType: salaryDefaults.incentiveBonusType || "",
        relocationBonus: salaryDefaults.relocationBonus || "",
        relocationBonusType: salaryDefaults.relocationBonusType || "",
        signOnBonus: salaryDefaults.signOnBonus || "",
        signOnBonusType: salaryDefaults.signOnBonusType || "",
        healthInsurance: salaryDefaults.healthInsurance || null,
        paidMalpractice: salaryDefaults.paidMalpractice || null,
        matching401k: salaryDefaults.matching401k || null,
        cmeAllowance: salaryDefaults.cmeAllowance || null,
        paidVacationDays: salaryDefaults.paidVacationDays || "",
        otherBenefits: salaryDefaults.otherBenefits || "",
        experienceYears: salaryDefaults.experienceYears || "",
        currentEmployerYears: salaryDefaults.currentEmployerYears || "",
        race: salaryDefaults.race || null,
        gender: salaryDefaults.gender || null,
        employmentType: salaryDefaults.employmentType || "",
        employmentClassification: salaryDefaults.employmentClassification || "",
        practiceType: salaryDefaults.practiceType || "",
        location: salaryDefaults.location || "",
        communitySize: salaryDefaults.communitySize || "",
        additionalComments: salaryDefaults.additionalComments || "",
        email: salaryDefaults.email || "",
      }));
    }
  }, [salaryDefaults]);

  const handleInputChange = (key, value) => {
    setSalaryData((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    // Validate the field after setting the value.
    validateField(key, value);
  };

  const handleShowBonuses = () => {
    setIsBonusActive(!isBonusActive);
    setShowBonuses(!showBonuses);
  };

  const handleShowBenefits = () => {
    setIsBenefitsActive(!isBenefitsActive);
    setShowBenefits(!showBenefits);
  };

  const handleShowPersonalDemographics = () => {
    setIsPersonalDemographicsActive(!isPersonalDemographicsActive);
    setShowPersonalDemographics(!showPersonalDemographics);
  };

  const validateField = (key, value) => {
    let error = null;

    // Define a simple email RegExp
    let emailRegEx = /^([\w-.]+@([\w-]+\.)+[\w-]{2,24})?$/;
    let floatRegEx = /^\d+(\.\d+)?$/;

    // Handle different validation rules based on which field we're validating.
    switch (key) {
      case "specialty":
      case "payPeriod":
      case "employmentType":
      case "employmentClassification":
      case "practiceType":
      case "location":
      case "communitySize":
        // All of these are dropdown fields. They must be non-empty.
        if (!value) {
          error = "This field is required";
        }
        break;
      case "otherSpecialty":
        // 'otherSpecialty' is required only when 'specialty' is "Other"
        if (salaryData.specialty === "Other" && !value) {
          error = "This field is required";
        }
        break;
      case "salary":
      case "experienceYears":
      case "currentEmployerYears":
        // These are numeric fields. They must be non-empty and numeric.
        if (!value) {
          error = "This field is required";
        } else if (value && !floatRegEx.test(value)) {
          error = "Please enter numbers only";
        }
        break;
      case "incentiveBonus":
      case "relocationBonus":
      case "signOnBonus":
      case "paidVacationDays":
        // These are numeric fields. They must be numeric.
        if (value && !floatRegEx.test(value)) {
          error = "Please enter numbers only";
        }
        break;
      case "email":
        if (value && !emailRegEx.test(value)) {
          error = "Please enter a valid email!";
        }
        break;
      default:
        // do nothing
        break;
    }

    // Update the errors state with the new error.
    setErrors((prevState) => ({
      ...prevState,
      [key]: error,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Set submitted to true
    setSubmitted(true);

    // Validate all fields
    for (let key in salaryData) {
      validateField(key, salaryData[key]);
    }

    // Check if there are any errors
    for (let key in errors) {
      if (errors[key]) {
        return; // Do not submit the form if there are errors
      }
    }

    let payload = { ...salaryData };

    // Delete properties with empty string values
    for (let key in payload) {
      if (payload[key] === "") {
        delete payload[key];
      }
    }

    // Prevent double submissions
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true); // Set isSubmitting to true

    axios
      .post(`${apiBaseURL}/api/compensation`, payload, {
        withCredentials: true,
      })
      .then((response) => {
        toast.success("Your submission was successful!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }); // Show success toast
        navigate("/salaries"); // Redirect to Salary page
        setIsSubmitting(false);
        TrackingService.recordEvent(
          TRACKING_EVENT.ASF_ADD_SALARY_FORM_SUBMITTED,
          {
            status: "Success",
          }
        );
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (submitted) {
      const errorCount = countErrors();
      
      if (errorCount > 0) {
        setErrorMessage(
          `Please fix the ${errorCount} errors above and then submit again.`
        );
        if (isSubmitting) {
          TrackingService.recordEvent(
            TRACKING_EVENT.ASF_ADD_SALARY_FORM_SUBMITTED,
            {
              status: "Failure",
              errorCount: errorCount,
            }
          );
          // setIsSubmitting(false);
        }
      } else {
        setErrorMessage("");
      }
    }
  }, [errors, submitted, countErrors]);

  return (
    <div className="section">
      <div className="add-salary-intro">
        <span className="add-salary-intro-title">
          Contribute your Compensation Data
        </span>
        <span className="add-salary-intro-subtitle">
          Anonymously contribute your compensation data to help your peers and
          to drive positive change in the industry.
        </span>
      </div>
      <Row>
        <Col md={4}>
          <h1 className="section-heading">Specialty</h1>
          <p className="section-subheading">
            Select the primary specialty you work in.
          </p>
        </Col>
        <Col md={8}>
          <div className="section-form-container">
            {/* Physician's Specialty */}
            <Form.Group>
              <Form.Label>
                Physician's Specialty <span className="text-error">*</span>
              </Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                title={salaryData.specialty || "Select Specialty"}
                className={`button-dropdown ${
                  errors.specialty && submitted ? "error-dropdown" : ""
                }`}
                onSelect={(eventKey) => {
                  handleInputChange("specialty", eventKey);
                  setOtherSpecialty(eventKey === "Other");
                  TrackingService.recordEvent(
                    TRACKING_EVENT.ASF_SPECIALTY_SELECTED,
                    {
                      specialty: eventKey,
                    }
                  );
                }}
              >
                {specialties.map((specialty) => (
                  <Dropdown.Item
                    key={specialty}
                    as="button"
                    eventKey={specialty}
                    className="dropdown-menu"
                  >
                    {specialty}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {errors.specialty && submitted && (
                <div className="error-message text-error">
                  {errors.specialty}
                </div>
              )}
            </Form.Group>

            {/* Other Specialty */}
            {salaryData.specialty === "Other" && (
              <Form.Group>
                <Form.Label>
                  Other <span className="text-error">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Specify other specialty"
                  className={`other-input ${
                    errors.otherSpecialty && submitted ? "error-input" : ""
                  }`}
                  value={salaryData.otherSpecialty}
                  onChange={(e) =>
                    handleInputChange("otherSpecialty", e.target.value)
                  }
                  name="otherSpecialty"
                  onFocus={() =>
                    validateField("otherSpecialty", salaryData.otherSpecialty)
                  }
                  onBlur={() =>
                    TrackingService.recordEvent(
                      TRACKING_EVENT.ASF_OTHER_SPECIALTY_ENTERED,
                      { otherSpecialty: salaryData.otherSpecialty }
                    )
                  }
                />
                {errors.otherSpecialty && submitted && (
                  <div className="error-message text-error">
                    {errors.otherSpecialty}
                  </div>
                )}
              </Form.Group>
            )}
          </div>
        </Col>
      </Row>
      <div className="section-divider"></div>

      <Row>
        <Col md={4}>
          <h1 className="section-heading">Compensation</h1>
          <p className="section-subheading">
            Please provide information on your salary, bonuses, and benefits.
          </p>
        </Col>
        <Col md={8}>
          <div className="section-form-container">
            {/* Salary */}
            <Form.Group className="salary-container">
              <Form.Label>
                Salary <span className="text-error">*</span>
              </Form.Label>
              <InputGroup>
                <NumericFormat
                  value={salaryData.salary}
                  onValueChange={(values) => {
                    const { value } = values;
                    handleInputChange("salary", value);
                    validateField("salary", value);
                  }}
                  thousandSeparator
                  prefix="$"
                  placeholder="Enter salary"
                  customInput={Form.Control}
                  type="text"
                  className={`salary-input ${
                    errors.salary && submitted ? "error-input" : ""
                  }`}
                  name="salary"
                  onFocus={() => validateField("salary", salaryData.salary)}
                  onBlur={() =>
                    TrackingService.recordEvent(TRACKING_EVENT.ASF_SALARY_ENTERED, {})
                  }
                />

                {/* Pay Period */}
                <DropdownButton
                  id="dropdown-button-addon"
                  title={salaryData.payPeriod || "Select Pay Period"}
                  className={`button-dropdown add-salary-dropdowns ${
                    errors.payPeriod && submitted ? "error-dropdown" : ""
                  }`}
                  onSelect={(eventKey) => {
                    handleInputChange("payPeriod", eventKey);
                    TrackingService.recordEvent(
                      TRACKING_EVENT.ASF_PAY_PERIOD_CHANGED,
                      {
                        Period: eventKey,
                      }
                    );
                  }}
                >
                  {["Annually", "Monthly", "Hourly"].map((period) => (
                    <Dropdown.Item
                      key={period}
                      as="button"
                      eventKey={period}
                      className="dropdown-menu"
                    >
                      {period}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>

                {errors.payPeriod && submitted && (
                  <div className="error-message text-error">
                    {errors.payPeriod}
                  </div>
                )}
              </InputGroup>
              {errors.salary && submitted && (
                <div className="error-message text-error">{errors.salary}</div>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Additional Compensation</Form.Label>
              <div>
                <Button
                  className={`form-expansion-button ${
                    isBonusActive ? "active" : ""
                  }`}
                  onClick={() => {
                    handleShowBonuses();
                    if (!isBonusActive) {
                      TrackingService.recordEvent(TRACKING_EVENT.ASF_BONUSES_EXPANDED, {});
                    } else {
                      TrackingService.recordEvent(
                        TRACKING_EVENT.ASF_BONUSES_COLLAPSED, {});}
                  }}
                >
                  Add Bonuses <AddIcon className="add-icon" />
                </Button>

                <Button className="form-expansion-button" disabled>
                  Add Equity (coming soon) <AddIcon className="add-icon" />
                </Button>
              </div>
            </Form.Group>

            {showBonuses && (
              <div className="section-subform-container">
                <Form.Label>Bonuses</Form.Label>
                {/* Incentive Bonus */}
                <Form.Group className="bonus-container">
                  <Form.Label>Incentive Bonus</Form.Label>
                  <InputGroup>
                    <NumericFormat
                      value={salaryData.incentiveBonus}
                      onValueChange={(values) => {
                        const { value } = values;
                        handleInputChange("incentiveBonus", value);
                        validateField("incentiveBonus", value);
                      }}
                      thousandSeparator={true}
                      prefix={salaryData.incentiveBonusType === "$" ? "$" : ""}
                      suffix={salaryData.incentiveBonusType === "%" ? "%" : ""}
                      placeholder="Enter bonus value"
                      customInput={Form.Control}
                      type="text"
                      className={`bonus-input ${
                        errors.incentiveBonus && submitted ? "error-input" : ""
                      }`}
                      name="incentiveBonus"
                      onFocus={() =>
                        validateField(
                          "incentiveBonus",
                          salaryData.incentiveBonus
                        )
                      }
                      onBlur={() =>
                        TrackingService.recordEvent(TRACKING_EVENT.ASF_INCENTIVE_BONUS_ENTERED, {})}
                    />
                    <DropdownButton
                      id="dropdown-button-addon"
                      title={salaryData.incentiveBonusType || "Select Type"}
                      className="button-dropdown"
                      onSelect={(eventKey) => {
                        handleInputChange("incentiveBonusType", eventKey);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_INCENTIVE_BONUS_TYPE_CHANGED,
                          {
                            bonusType: eventKey,
                          }
                        );
                      }}
                    >
                      {["$", "%"].map((type) => (
                        <Dropdown.Item
                          key={type}
                          as="button"
                          eventKey={type}
                          className="dropdown-menu"
                        >
                          {type}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </InputGroup>
                  {errors.incentiveBonus && submitted && (
                    <div className="error-message text-error">
                      {errors.incentiveBonus}
                    </div>
                  )}
                </Form.Group>

                {/* Relocation Bonus */}
                <Form.Group className="bonus-container">
                  <Form.Label>Relocation Bonus</Form.Label>
                  <InputGroup>
                    <NumericFormat
                      value={salaryData.relocationBonus}
                      onValueChange={(values) => {
                        const { value } = values;
                        handleInputChange("relocationBonus", value);
                        validateField("relocationBonus", value);
                      }}
                      thousandSeparator={true}
                      prefix={salaryData.relocationBonusType === "$" ? "$" : ""}
                      suffix={salaryData.relocationBonusType === "%" ? "%" : ""}
                      placeholder="Enter bonus value"
                      customInput={Form.Control}
                      type="text"
                      className={`bonus-input ${
                        errors.relocationBonus && submitted ? "error-input" : ""
                      }`}
                      name="relocationBonus"
                      onFocus={() =>
                        validateField(
                          "relocationBonus",
                          salaryData.relocationBonus
                        )
                      }
                      onBlur={() =>
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_RELOCATION_BONUS_ENTERED, {}
                        )
                      }
                    />
                    <DropdownButton
                      id="dropdown-button-addon"
                      title={salaryData.relocationBonusType || "Select Type"}
                      className="button-dropdown"
                      onSelect={(eventKey) => {
                        handleInputChange("relocationBonusType", eventKey);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_RELOCATION_BONUS_TYPE_CHANGED,
                          {
                            bonusType: eventKey,
                          }
                        );
                      }}
                    >
                      {["$", "%"].map((type) => (
                        <Dropdown.Item
                          key={type}
                          as="button"
                          eventKey={type}
                          className="dropdown-menu"
                        >
                          {type}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </InputGroup>
                  {errors.relocationBonus && submitted && (
                    <div className="error-message text-error">
                      {errors.relocationBonus}
                    </div>
                  )}
                </Form.Group>

                {/* Sign On Bonus */}
                <Form.Group className="bonus-container">
                  <Form.Label>Sign On Bonus</Form.Label>
                  <InputGroup>
                    <NumericFormat
                      value={salaryData.signOnBonus}
                      onValueChange={(values) => {
                        const { value } = values;
                        handleInputChange("signOnBonus", value);
                        validateField("signOnBonus", value);
                      }}
                      thousandSeparator={true}
                      prefix={salaryData.signOnBonusType === "$" ? "$" : ""}
                      suffix={salaryData.signOnBonusType === "%" ? "%" : ""}
                      placeholder="Enter bonus value"
                      customInput={Form.Control}
                      type="text"
                      className={`bonus-input ${
                        errors.signOnBonus && submitted ? "error-input" : ""
                      }`}
                      name="signOnBonus"
                      onFocus={() =>
                        validateField("signOnBonus", salaryData.signOnBonus)
                      }
                      onBlur={() =>
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_SIGN_ON_BONUS_ENTERED, {}
                        )
                      }
                    />
                    <DropdownButton
                      id="dropdown-button-addon"
                      title={salaryData.signOnBonusType || "Select Type"}
                      className="button-dropdown"
                      onSelect={(eventKey) => {
                        handleInputChange("signOnBonusType", eventKey);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_SIGN_ON_BONUS_TYPE_CHANGED,
                          {
                            bonusType: eventKey,
                          }
                        );
                      }}
                    >
                      {["$", "%"].map((type) => (
                        <Dropdown.Item
                          key={type}
                          as="button"
                          eventKey={type}
                          className="dropdown-menu"
                        >
                          {type}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </InputGroup>
                  {errors.signOnBonus && submitted && (
                    <div className="error-message text-error">
                      {errors.signOnBonus}
                    </div>
                  )}
                </Form.Group>
              </div>
            )}

            <Form.Group>
              <Form.Label>Additional Benefits</Form.Label>
              <div>
                <Button
                  className={`form-expansion-button ${
                    isBenefitsActive ? "active" : ""
                  }`}
                  onClick={() => {
                    handleShowBenefits();
                    if (!isBenefitsActive) {
                      TrackingService.recordEvent(
                        TRACKING_EVENT.ASF_BENEFITS_EXPANDED, {}
                      );
                    } else {
                      TrackingService.recordEvent(
                        TRACKING_EVENT.ASF_BENEFITS_COLLAPSED, {}
                      );
                    }
                  }}
                >
                  Add Benefits <AddIcon className="add-icon" />
                </Button>
              </div>
            </Form.Group>

            {showBenefits && (
              <div className="section-subform-container">
                <Form.Label>Benefits</Form.Label>
                {/* Health Insurance */}
                <Form.Group>
                  <Form.Label>Health Insurance</Form.Label>
                  <div className="radio-button-group">
                    <Form.Check
                      type="radio"
                      inline
                      name="healthInsurance"
                      label="Yes"
                      checked={salaryData.healthInsurance === true}
                      onChange={() => {
                        handleInputChange("healthInsurance", true);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_HEALTH_INSURANCE_CHECKED, {}
                        );
                      }}
                    />
                    <Form.Check
                      type="radio"
                      inline
                      name="healthInsurance"
                      label="No"
                      checked={salaryData.healthInsurance === false}
                      onChange={() => {
                        handleInputChange("healthInsurance", false);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_HEALTH_INSURANCE_CHECKED, {}
                        );
                      }}
                    />
                  </div>
                </Form.Group>

                {/* Paid Malpractice */}
                <Form.Group>
                  <Form.Label>Paid Malpractice</Form.Label>
                  <div className="radio-button-group">
                    <Form.Check
                      type="radio"
                      inline
                      name="paidMalpractice"
                      label="Yes"
                      checked={salaryData.paidMalpractice === true}
                      onChange={() => {
                        handleInputChange("paidMalpractice", true);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_PAID_MALPRACTICE_CHECKED, {}
                        );
                      }}
                    />
                    <Form.Check
                      type="radio"
                      inline
                      name="paidMalpractice"
                      label="No"
                      checked={salaryData.paidMalpractice === false}
                      onChange={() => {
                        handleInputChange("paidMalpractice", false);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_PAID_MALPRACTICE_CHECKED, {}
                        );
                      }}
                    />
                  </div>
                </Form.Group>

                {/* Matching 401k */}
                <Form.Group>
                  <Form.Label>Matching 401k</Form.Label>
                  <div className="radio-button-group">
                    <Form.Check
                      type="radio"
                      inline
                      name="matching401k"
                      label="Yes"
                      checked={salaryData.matching401k === true}
                      onChange={() => {
                        handleInputChange("matching401k", true);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_MATCHING_401K_CHECKED, {}
                        );
                      }}
                    />
                    <Form.Check
                      type="radio"
                      inline
                      name="matching401k"
                      label="No"
                      checked={salaryData.matching401k === false}
                      onChange={() => {
                        handleInputChange("matching401k", false);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_MATCHING_401K_CHECKED, {}
                        );
                      }}
                    />
                  </div>
                </Form.Group>

                {/* CME Allowance */}
                <Form.Group>
                  <Form.Label>CME Allowance</Form.Label>
                  <div className="radio-button-group">
                    <Form.Check
                      type="radio"
                      inline
                      name="cmeAllowance"
                      label="Yes"
                      checked={salaryData.cmeAllowance === true}
                      onChange={() => {
                        handleInputChange("cmeAllowance", true);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_CME_ALLOWANCE_CHECKED, {}
                        );
                      }}
                    />
                    <Form.Check
                      type="radio"
                      inline
                      name="cmeAllowance"
                      label="No"
                      checked={salaryData.cmeAllowance === false}
                      onChange={() => {
                        handleInputChange("cmeAllowance", false);
                        TrackingService.recordEvent(
                          TRACKING_EVENT.ASF_CME_ALLOWANCE_CHECKED, {}
                        );
                      }}
                    />
                  </div>
                </Form.Group>

                {/* Paid Vacation Days */}
                <Form.Group>
                  <Form.Label>Paid Vacation Days</Form.Label>
                  <Form.Control
                    type="text"
                    pattern="\d*"
                    inputMode="numeric"
                    placeholder="Enter # of days"
                    min={0}
                    value={salaryData.paidVacationDays}
                    onChange={(e) => {
                      handleInputChange("paidVacationDays", e.target.value);
                      validateField("paidVacationDays", e.target.value);
                    }}
                    className={`paid-vacation-input ${
                      errors.paidVacationDays && submitted ? "error-input" : ""
                    }`}
                    name="paidVacationDays"
                    onFocus={() =>
                      validateField(
                        "paidVacationDays",
                        salaryData.paidVacationDays
                      )
                    }
                    onBlur={() =>
                      TrackingService.recordEvent(
                        TRACKING_EVENT.ASF_PAID_VACATION_DAYS_ENTERED, {}
                      )
                    }
                    // style={{ width: "180px" }}
                  />
                  {errors.paidVacationDays && submitted && (
                    <div className="error-message text-error">
                      {errors.paidVacationDays}
                    </div>
                  )}
                </Form.Group>

                {/* Other Benefits */}
                <Form.Group>
                  <Form.Label>Other Benefits</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder="Specify other benefits"
                    value={salaryData.otherBenefits || ""}
                    onChange={(e) =>
                      handleInputChange("otherBenefits", e.target.value)
                    }
                    onBlur={() =>
                      TrackingService.recordEvent(
                        TRACKING_EVENT.ASF_OTHER_BENEFITS_ENTERED,
                        { otherBenefits: salaryData.otherBenefits }
                      )
                    }
                    className="custom-textarea"
                  />
                </Form.Group>
              </div>
            )}
          </div>
        </Col>
      </Row>

      <div className="section-divider"></div>
      <Row>
        <Col md={4}>
          <h1 className="section-heading">Demographics</h1>
          <p className="section-subheading">
            Please share demographical information to contextualize your
            compensation.
          </p>
        </Col>
        <Col md={8}>
          <div className="section-form-container">
            {/* Years of Experience as Attending */}
            <Form.Group className="yoe-container">
              <Form.Label>
                Years of Experience as Attending{" "}
                <span className="text-error">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                pattern="\d*"
                inputMode="numeric"
                placeholder="Enter # of years"
                min={0}
                value={salaryData.experienceYears}
                onChange={(e) => {
                  handleInputChange("experienceYears", e.target.value);
                  validateField("experienceYears", e.target.value);
                }}
                className={`yoe-input ${
                  errors.experienceYears && submitted ? "error-input" : ""
                }`}
                name="experienceYears"
                onFocus={() =>
                  validateField("experienceYears", salaryData.experienceYears)
                }
                onBlur={() =>
                  TrackingService.recordEvent(
                    TRACKING_EVENT.ASF_YEARS_OF_EXPERIENCE_ENTERED,
                    { experienceYears: salaryData.experienceYears }
                  )
                }
              />
              {errors.experienceYears && submitted && (
                <div className="error-message text-error">
                  {errors.experienceYears}
                </div>
              )}
            </Form.Group>

            {/* Years at Current Employer */}
            <Form.Group className="yace-container">
              <Form.Label>
                Years at Current Employer <span className="text-error">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                pattern="\d*"
                inputMode="numeric"
                placeholder="Enter # of years"
                min={0}
                value={salaryData.currentEmployerYears}
                onChange={(e) => {
                  handleInputChange("currentEmployerYears", e.target.value);
                  validateField("currentEmployerYears", e.target.value);
                }}
                className={`yace-input ${
                  errors.currentEmployerYears && submitted ? "error-input" : ""
                }`}
                name="currentEmployerYears"
                onFocus={() =>
                  validateField(
                    "currentEmployerYears",
                    salaryData.currentEmployerYears
                  )
                }
                onBlur={() =>
                  TrackingService.recordEvent(
                    TRACKING_EVENT.ASF_YEARS_AT_EMPLOYER_ENTERED,
                    { currentEmployerYears: salaryData.currentEmployerYears }
                  )
                }
              />
              {errors.currentEmployerYears && submitted && (
                <div className="error-message text-error">
                  {errors.currentEmployerYears}
                </div>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Personal Demographics</Form.Label>
              <div>
                <Button
                  className={`form-expansion-button ${
                    isPersonalDemographicsActive ? "active" : ""
                  }`}
                  onClick={() => {
                    handleShowPersonalDemographics();
                    if (!isPersonalDemographicsActive) {
                      TrackingService.recordEvent(
                        TRACKING_EVENT.ASF_RACE_GENDER_EXPANDED, {}
                      );
                    } else {
                      TrackingService.recordEvent(
                        TRACKING_EVENT.ASF_RACE_GENDER_COLLAPSED, {}
                      );
                    }
                  }}
                >
                  Share Race/Gender <AddIcon className="add-icon" />
                </Button>
              </div>
            </Form.Group>
            {showPersonalDemographics && (
              <div className="section-subform-container">
                {/* Race */}
                <Form.Group>
                  <Form.Label>Race</Form.Label>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={salaryData.race || "Select Race"}
                    onSelect={(eventKey) => {
                      handleInputChange("race", eventKey);
                      TrackingService.recordEvent(
                        TRACKING_EVENT.ASF_RACE_SELECTED,
                        {
                          race: eventKey,
                        }
                      );
                    }}
                  >
                    {races.map((option) => (
                      <Dropdown.Item
                        key={option}
                        eventKey={option}
                        className="dropdown-menu"
                      >
                        {option}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>

                {/* Gender */}
                <Form.Group>
                  <Form.Label>Gender</Form.Label>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={salaryData.gender || "Select Gender"}
                    onSelect={(eventKey) => {
                      handleInputChange("gender", eventKey);
                      TrackingService.recordEvent(
                        TRACKING_EVENT.ASF_GENDER_SELECTED,
                        {
                          gender: eventKey,
                        }
                      );
                    }}
                  >
                    {genders.map((option) => (
                      <Dropdown.Item
                        key={option}
                        eventKey={option}
                        className="dropdown-menu"
                      >
                        {option}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
              </div>
            )}

            {/* Employment Type */}
            <Form.Group>
              <Form.Label>
                Employment Type <span className="text-error">*</span>
              </Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                title={salaryData.employmentType || "Select Employment Type"}
                className={`button-dropdown ${
                  errors.employmentType && submitted ? "error-dropdown" : ""
                }`}
                onSelect={(eventKey) => {
                  handleInputChange("employmentType", eventKey);
                  TrackingService.recordEvent(
                    TRACKING_EVENT.ASF_EMPLOYMENT_TYPE_SELECTED,
                    {
                      employmentType: eventKey,
                    }
                  );
                }}
              >
                {employmentTypes.map((option) => (
                  <Dropdown.Item
                    key={option}
                    eventKey={option}
                    className="dropdown-menu"
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {errors.employmentType && submitted && (
                <div className="error-message text-error">
                  {errors.employmentType}
                </div>
              )}
            </Form.Group>

            {/* Employment Classification */}
            <Form.Group>
              <Form.Label>
                Employment Classification <span className="text-error">*</span>
              </Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="W2"
                  type="radio"
                  id={`inline-radio-1`}
                  value="W2"
                  checked={salaryData.employmentClassification === "W2"}
                  onChange={(e) => {
                    handleInputChange(
                      "employmentClassification",
                      e.target.value
                    );
                    TrackingService.recordEvent(
                      TRACKING_EVENT.ASF_EMPLOYMENT_CLASS_CHECKED,
                      {
                        employmentClassification: e.target.value,
                      }
                    );
                  }}
                />
                <Form.Check
                  inline
                  label="1099"
                  type="radio"
                  id={`inline-radio-2`}
                  value="1099"
                  checked={salaryData.employmentClassification === "1099"}
                  onChange={(e) => {
                    handleInputChange(
                      "employmentClassification",
                      e.target.value
                    );
                    TrackingService.recordEvent(
                      TRACKING_EVENT.ASF_EMPLOYMENT_CLASS_CHECKED,
                      {
                        employmentClassification: e.target.value,
                      }
                    );
                  }}
                />
              </div>
              {errors.employmentClassification && submitted && (
                <div className="error-message text-error">
                  {errors.employmentClassification}
                </div>
              )}
            </Form.Group>

            {/* Practice Type */}
            <Form.Group>
              <Form.Label>
                Practice Type <span className="text-error">*</span>
              </Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                title={salaryData.practiceType || "Select Practice Type"}
                className={`button-dropdown ${
                  errors.practiceType && submitted ? "error-dropdown" : ""
                }`}
                onSelect={(eventKey) => {
                  handleInputChange("practiceType", eventKey);
                  TrackingService.recordEvent(
                    TRACKING_EVENT.ASF_PRACTICE_TYPE_SELECTED,
                    {
                      practiceType: eventKey,
                    }
                  );
                }}
              >
                {practiceTypes.map((option) => (
                  <Dropdown.Item
                    key={option}
                    eventKey={option}
                    className="dropdown-menu"
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {errors.practiceType && submitted && (
                <div className="error-message text-error">
                  {errors.practiceType}
                </div>
              )}
            </Form.Group>

            {/* Location */}
            <Form.Group>
              <Form.Label>
                Location <span className="text-error">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City, State, or Region"
                className={`location-input ${
                  errors.location && submitted ? "error-input" : ""
                }`}
                value={salaryData.location}
                onChange={(e) => handleInputChange("location", e.target.value)}
                name="location"
                onFocus={() => validateField("location", salaryData.location)}
                onBlur={() =>
                  TrackingService.recordEvent(
                    TRACKING_EVENT.ASF_LOCATION_ENTERED,
                    { location: salaryData.location }
                  )
                }
              />
              {errors.location && submitted && (
                <div className="error-message text-error">
                  {errors.location}
                </div>
              )}
            </Form.Group>

            {/* Community Size */}
            <Form.Group>
              <Form.Label>
                Community Size <span className="text-error">*</span>
              </Form.Label>
              <DropdownButton
                id="dropdown-basic-button"
                title={salaryData.communitySize || "Select Community Size"}
                className={`button-dropdown ${
                  errors.communitySize && submitted ? "error-dropdown" : ""
                }`}
                onSelect={(eventKey) => {
                  handleInputChange("communitySize", eventKey);
                  TrackingService.recordEvent(
                    TRACKING_EVENT.ASF_COMMUNITY_SIZE_SELECTED,
                    {
                      communitySize: eventKey,
                    }
                  );
                }}
              >
                {/* TODO: Define communitySizes */}
                {communitySizes.map((option) => (
                  <Dropdown.Item
                    key={option}
                    eventKey={option}
                    className="dropdown-menu"
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {errors.communitySize && submitted && (
                <div className="error-message text-error">
                  {errors.communitySize}
                </div>
              )}
            </Form.Group>
          </div>
        </Col>
      </Row>

      <div className="section-divider"></div>
      <Row>
        <Col md={4}>
          <h1 className="section-heading">Optional Details</h1>
          <p className="section-subheading">
            Please share any comments on your compensation. These will not be
            published.
          </p>
        </Col>
        <Col md={8}>
          <div className="section-form-container">
            {/* Additional Comments */}
            <Form.Group controlId="formBasicComments">
              <Form.Label>Additional Comments</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Please share any relevant feedback about your submission."
                rows={3}
                value={salaryData.additionalComments}
                onChange={(e) => {
                  handleInputChange("additionalComments", e.target.value);
                }}
                onBlur={() =>
                  TrackingService.recordEvent(
                    TRACKING_EVENT.ASF_ADDITIONAL_COMMENTS_ENTERED,
                    { additionalComments: salaryData.additionalComments }
                  )
                }
                className="custom-textarea"
              />
            </Form.Group>

            {/* Email */}
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="info@outlier.care"
                value={salaryData.email}
                onChange={(e) => {
                  handleInputChange("email", e.target.value);
                  validateField("email", e.target.value);
                }}
                onBlur={() =>
                  TrackingService.recordEvent(
                    TRACKING_EVENT.ASF_EMAIL_ENTERED,
                    { email: salaryData.email }
                  )
                }
                className={`email-input ${errors.email ? "error-input" : ""}`}
              />
              {errors.email && <div className="text-error">{errors.email}</div>}
              <Form.Text className="text-muted">
                Providing an email allows for editing or deleting of your
                submission. Your email will not be shared.
              </Form.Text>
            </Form.Group>
          </div>
        </Col>
      </Row>

      <div className="button-container">
        {errorMessage && (
          <div className="error-submit text-error">{errorMessage}</div>
        )}
        <Button
          className="button"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          Submit Compensation
        </Button>
      </div>
    </div>
  );
}

export default AddSalary;
