export const TRACKING_EVENT = {
  VISITED_SITE: 'Visited Site',
  SALARIES_PAGE_OPENED: 'Salaries Page Opened',
  SALARIES_SEARCHED: 'Salaries Searched',
  SALARY_DETAILS_EXPANDED: 'Salary Details Expanded',
  JOB_PAGE_OPENED: 'Job Page Opened',
  JOB_LIST_SEARCHED: 'Job List Searched',
  JOB_OPENED: 'Job Opened',
  SALARY_CHART_CLICKED: 'Salary Chart Clicked',
  SALARY_FILTER_ADDED: 'Salary Filter Added',
  SALARY_FILTER_REMOVED: 'Salary Filter Removed',
  ASF_ADD_SALARY_PAGE_OPENED: 'Add Salary Form Page Opened',
  ASF_SPECIALTY_SELECTED: 'ASF | Specialty Selected',
  ASF_OTHER_SPECIALTY_ENTERED: 'ASF | Other Specialty Entered',
  ASF_SALARY_ENTERED: 'ASF | Salary Entered',
  ASF_PAY_PERIOD_CHANGED: 'ASF | Pay Period Changed',
  ASF_BONUSES_EXPANDED: 'ASF | Bonuses Expanded',
  ASF_BONUSES_COLLAPSED: 'ASF | Bonuses Collapsed',
  ASF_INCENTIVE_BONUS_ENTERED: 'ASF | Incentive Bonus Entered',
  ASF_INCENTIVE_BONUS_TYPE_CHANGED: 'ASF | Incentive Bonus Type Changed',
  ASF_RELOCATION_BONUS_ENTERED: 'ASF | Relocation Bonus Entered',
  ASF_RELOCATION_BONUS_TYPE_CHANGED: 'ASF | Relocation Bonus Type Changed',
  ASF_SIGN_ON_BONUS_ENTERED: 'ASF | Sign-on Bonus Entered',
  ASF_SIGN_ON_BONUS_TYPE_CHANGED: 'ASF | Sign-on Bonus Type Changed',
  ASF_BENEFITS_EXPANDED: 'ASF | Benefits Expanded',
  ASF_BENEFITS_COLLAPSED: 'ASF | Benefits Collapsed',
  ASF_HEALTH_INSURANCE_CHECKED: 'ASF | Health Insurance Checked',
  ASF_PAID_MALPRACTICE_CHECKED: 'ASF | Paid Malpractice Checked',
  ASF_MATCHING_401K_CHECKED: 'ASF | Matching 401K Checked',
  ASF_CME_ALLOWANCE_CHECKED: 'ASF | CME Allowance Checked',
  ASF_PAID_VACATION_DAYS_ENTERED: 'ASF | Paid Vacation Days Entered',
  ASF_OTHER_BENEFITS_ENTERED: 'ASF | Other Benefits Entered',
  ASF_YEARS_OF_EXPERIENCE_ENTERED: 'ASF | Years of Experience Entered',
  ASF_YEARS_AT_EMPLOYER_ENTERED: 'ASF | Years at Employer Entered',
  ASF_RACE_GENDER_EXPANDED: 'ASF | Race/Gender Expanded',
  ASF_RACE_GENDER_COLLAPSED: 'ASF | Race/Gender Collapsed',
  ASF_RACE_SELECTED: 'ASF | Race Selected',
  ASF_GENDER_SELECTED: 'ASF | Gender Selected',
  ASF_EMPLOYMENT_TYPE_SELECTED: 'ASF | Employment Type Selected',
  ASF_EMPLOYMENT_CLASS_CHECKED: 'ASF | Employment Class Checked',
  ASF_PRACTICE_TYPE_SELECTED: 'ASF | Practice Type Selected',
  ASF_LOCATION_ENTERED: 'ASF | Location Entered',
  ASF_COMMUNITY_SIZE_SELECTED: 'ASF | Community Size Selected',
  ASF_ADDITIONAL_COMMENTS_ENTERED: 'ASF | Additional Comments Entered',
  ASF_EMAIL_ENTERED: 'ASF | Email Entered',
  ASF_ADD_SALARY_FORM_SUBMITTED: 'ASF | Add Salary Form Submitted',
  ABOUT_PAGE_OPENED: 'About Page Opened',
  ABOUT_TEAM_SOCIALS_CLICKED: 'About Team Socials Clicked',
};

