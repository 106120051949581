import React, { useEffect } from "react";
import "../Styles/Components/About.css";
import jsHeadshot from "../Assets/JS-Headshot.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import useTrackingService from "../services/TrackingService";
import { TRACKING_EVENT } from "../utils/TrackingEvents";

const About = () => {
  const TrackingService = useTrackingService();

  useEffect(() => {
    document.title = 'About | Outlier.Care';
  
    return () => {
      document.title = 'Outlier.Care';
    };
  }, []);

  useEffect(() => {
    TrackingService.recordEvent(TRACKING_EVENT.ABOUT_PAGE_OPENED, {});
  }, []);

  return (
    <div className="about-container">
      <div className="left-column">
        <TextSection />
      </div>
      <div className="right-column">
        <TeamSection />
      </div>
    </div>
  );
};

const TextSection = () => {
  return (
    <div className="text-section">
      <h2>Helping physicians realize their worth</h2>
      <p>
        In early 2023, three close friends graduated residency and were
        fortunate to land multiple job offers as attending physicians. They
        sought my help to evaluate these offers: Are the monetary offers weak or
        strong? Are the benefits competitive with the market?
      </p>
      <p>
        Turns out, these are impossible to answer confidently without loads of
        awkward personal conversations in your peer group. Unfortunately,
        medicine doesn’t have a reliable way to assess physician compensation
        beyond simple averages.
      </p>
      <p>
        I built Outlier.Care to fix that. We provide physicians with safe and
        transparent tools to analyze their compensation and make informed
        decisions at the most granular level. We do this by parsing compensation
        data from 1000s of job postings, individual survey submissions, and{" "}
        <i>(coming soon)</i> verified W2/Offer letters.
      </p>
      <p>
        I built Outlier.Care because I believe in empowering physicians to
        capture more of the value they create in the world. This allows top
        physicians to negotiate from a place of leverage, and for staffing
        decision makers to budget accurately.
      </p>
    </div>
  );
};

const TeamSection = () => {
  const TrackingService = useTrackingService();

  return (
    <div className="team-section">
      <div className="team-card">
        <img src={jsHeadshot} alt="Josh Sabol" />
        <h3>Josh Sabol</h3>
        <p>Founder, Outlier.Care</p>
        <p>
          Prev. Product Manager @ Amazon,
          <br />
          1st PM @ Plate IQ
        </p>
        <div className="social-links">
          <a
            href="https://twitter.com/JoshSabol"
            target="_blank"
            rel="noopener noreferrer"
            className="TwitterIcon"
            onClick={(e) => {
              TrackingService.recordEvent(
                TRACKING_EVENT.ABOUT_TEAM_SOCIALS_CLICKED,
                {
                  link: e.target.href,
                }
              );
            }}
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href="https://www.linkedin.com/in/joshsabol/"
            target="_blank"
            rel="noopener noreferrer"
            className="LinkedInIcon"
            onClick={(e) => {
              TrackingService.recordEvent(
                TRACKING_EVENT.ABOUT_TEAM_SOCIALS_CLICKED,
                {
                  link: e.target.href,
                }
              );
            }}
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
